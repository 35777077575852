:root {
	--surface-1: #f5f5f5;
}

.map-meesage-container {
	position: absolute;
	left: 27%;
	top: 63px;
	background-color: rgba(0, 0, 0, 0.8);
	width: 164px;
	height: 35px;
	z-index: 3;
	border-radius: 30px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: var(--surface-1);
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	box-sizing: content-box;
	padding: 8px 20px;
	gap: 8px;
}
