/* root variables - set for 1920 x 1200 16:10 */
:root {
	--bg-font-family: 'Roboto', sans-serif;
	--bg-font-size-smallest: 8px;
	--bg-font-size-smaller: 10px;
	--bg-font-size-small: 12px;
	--bg-font-size: 14px;
	--bg-font-size-large: 16px;
	--bg-font-size-larger: 18px;
	--bg-font-size-largest: 20px;
	--bg-font-size-xl: 22px;
	--bg-font-size-xl2: 24px;
	--bg-font-size-xxl: 28px;
	--bg-opacity: 0.75;
	--bg-button-height: 40px;
	--bg-button-width: 36px;
	--bg-input-width: 320px;
	--bg-locationEditor-width: 300px;
	--bg-imageryDetails-width: 230px;
	--bg-mapPointDetails-width: 220px;
	--bg-navigator-height: 170px;
	--bg-navigator-width: calc(100% - 40px);
	--bg-imagerySelector-height: 20px;
	--bg-imagerySelector-width: calc(100% - 10px);
	--bg-imagerySelector-button-height: 24px;
	--bg-imagerySelector-button-width: 120px;
	--bg-imagerySelector-action-button-width: 90px;
	--bg-imagery-width: 100%;
	--bg-imageryBlock-height: 100px;
	--bg-imageryBlock-width: 100px;
	--bg-imageButton-width: 100px;
	--bg-imageButton-height: 100px;
	--bg-AOILibrary-height: 30px;
	--bg-AOILibrary-width: 400px;
	--bg-AOILibrary-select-arrow-offset: 12px;
	--bg-AOILibrary-list-height: 500px;
	--bg-branding-height: 40px;
	--bg-branding-width: 140px;
	--bg-hud-height: 30px;
	--bg-hud-line-height: 30px;
	--bg-attrib-bottom: 20px;
	--bg-attrib-width: 100px;
	--bg-scale: 100%;
}

* {
	font-family: var(--bg-font-family);
	font-size: var(--bg-font-size);
}

body {
	background-color: black !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* detect mobile devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
	@media only screen and (-webkit-min-device-pixel-ratio: 1) {
		:root {
			--bg-font-size-smallest: calc(8px - 2px);
			--bg-font-size-smaller: calc(10px - 2px);
			--bg-font-size-small: calc(12px - 2px);
			--bg-font-size: calc(14px - 2px);
			--bg-font-size-large: calc(16px - 2px);
			--bg-font-size-larger: calc(18px - 2px);
			--bg-font-size-largest: calc(20px - 2px);
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 2) {
		:root {
			--bg-font-size-smallest: calc(8px - 4px);
			--bg-font-size-smaller: calc(10px - 4px);
			--bg-font-size-small: calc(12px - 4px);
			--bg-font-size: calc(14px - 4px);
			--bg-font-size-large: calc(16px - 4px);
			--bg-font-size-larger: calc(18px - 4px);
			--bg-font-size-largest: calc(20px - 4px);
		}
	}
}

/* detect Safari */
@media not all and (min-resolution: 0.001dpcm) {
	:root {
		--bg-font-size-smallest: 8px;
		--bg-font-size-smaller: 10px;
		--bg-font-size-small: 12px;
		--bg-font-size: 14px;
		--bg-font-size-large: 16px;
		--bg-font-size-larger: 18px;
		--bg-font-size-largest: 20px;
		--bg-font-size-xl: 22px;
		--bg-font-size-xl2: 24px;
		--bg-font-size-xxl: 28px;
		--bg-opacity: 0.75;
		--bg-button-height: 40px;
		--bg-button-width: 36px;
		--bg-input-width: 320px;
		--bg-locationEditor-width: 300px;
		--bg-imageryDetails-width: 230px;
		--bg-mapPointDetails-width: 220px;
		--bg-navigator-height: 170px;
		--bg-navigator-width: calc(100% - 40px);
		--bg-imagerySelector-height: 20px;
		--bg-imagerySelector-width: calc(100% - 10px);
		--bg-imagerySelector-button-height: 24px;
		--bg-imagerySelector-button-width: 120px;
		--bg-imagerySelector-action-button-width: 90px;
		--bg-imagery-width: 100%;
		--bg-imageryBlock-height: 100px;
		--bg-imageryBlock-width: 100px;
		--bg-imageButton-width: 100px;
		--bg-imageButton-height: 100px;
		--bg-AOILibrary-height: 30px;
		--bg-AOILibrary-width: 400px;
		--bg-AOILibrary-select-arrow-offset: 12px;
		--bg-AOILibrary-list-height: 500px;
		--bg-branding-height: 40px;
		--bg-branding-width: 140px;
		--bg-hud-height: 30px;
		--bg-hud-line-height: 30px;
		--bg-attrib-bottom: 20px;
		--bg-attrib-width: 100px;
		--bg-scale: 100%;
	}
}

/* detect FHD Displays with toolbars*/

/*@media only screen and (min-width: 3456px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 130px;
        --bg-imageryBlock-height: 130px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}*/

/*
@media only screen and (min-width: 3072px) and (max-width: 3455px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 110px;
        --bg-navigator-width: calc(100% - 20px);
        --bg-imagery-width: calc(100% - 50px);
        --bg-imageryBlock-height: 110px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}

@media only screen and (min-width: 3026px) and (max-width: 3071px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 110px;
        --bg-imageryBlock-height: 110px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}
*/
/*@media only screen and (min-width: 2880px) and (max-width: 3023px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 130px;
        --bg-imageryBlock-height: 130px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}

@media only screen and (min-width: 2560px) and (max-width: 2879px) {
    :root {
        --bg-font-size-smallest: calc(8px + 2px);
        --bg-font-size-smaller: calc(10px + 2px);
        --bg-font-size-small: calc(12px + 2px);
        --bg-font-size: calc(14px + 2px);
        --bg-font-size-large: calc(16px + 2px);
        --bg-font-size-larger: calc(18px + 2px);
        --bg-font-size-largest: calc(20px + 2px);
        --bg-navigator-height: calc(110px + 10px);
        --bg-imageryBlock-height: calc(110px + 20px);
        --bg-imageryBlock-width: calc(110px + 30px);
        --bg-imageButton-height: 40px;
        --bg-AOILibrary-height: calc(40px + 15px);
        --bg-AOILibrary-width: calc(400px + 100px);
        --bg-AOILibrary-select-arrow-offset: calc(18px + 8px);
        --bg-branding-height: calc(40px + 10px);
        --bg-branding-width: calc(140px + 35px);
        --bg-hud-height: calc(40px + 10px);
        --bg-hud-line-height: calc(36px + 10px);
        --bg-attrib-bottom: calc(20px - 20px);
        --bg-attrib-width: calc(100px + 5px);
    }
}

@media only screen and (min-width: 2306px) and (max-width: 2559px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 110px;
        --bg-imageryBlock-height: 110px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 130px;
        --bg-imageryBlock-height: 130px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 30px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    :root {
        --bg-font-size-smallest: calc(8px + 2px);
        --bg-font-size-smaller: calc(10px + 2px);
        --bg-font-size-small: calc(12px + 2px);
        --bg-font-size: calc(14px + 2px);
        --bg-font-size-large: calc(16px + 2px);
        --bg-font-size-larger: calc(18px + 2px);
        --bg-font-size-largest: calc(20px + 2px);
        --bg-navigator-height: calc(110px + 10px);
        --bg-imageryBlock-height: calc(110px + 20px);
        --bg-imageryBlock-width: calc(110px + 30px);
        --bg-imageButton-height: 40px;
        --bg-attrib-bottom: calc(20px + 5px);
        --bg-attrib-width: calc(100px + 5px);
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1365px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 110px;
        --bg-imageryBlock-height: 110px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    :root {
        --bg-font-size-smallest: 8px;
        --bg-font-size-smaller: 10px;
        --bg-font-size-small: 12px;
        --bg-font-size: 14px;
        --bg-font-size-large: 16px;
        --bg-font-size-larger: 18px;
        --bg-font-size-largest: 20px;
        --bg-opacity: 0.75;
        --bg-button-height: 40px;
        --bg-button-width: 36px;
        --bg-input-width: 320px;
        --bg-locationEditor-width: 250px;
        --bg-imageryDetails-width: 230px;
        --bg-mapPointDetails-width: 220px;
        --bg-navigator-height: 110px;
        --bg-imageryBlock-height: 110px;
        --bg-imageryBlock-width: 130px;

        --bg-AOILibrary-height: 40px;
        --bg-AOILibrary-width: 400px;
        --bg-AOILibrary-select-arrow-offset: 18px;
        --bg-AOILibrary-list-height: calc(100% - 150px);
        --bg-branding-height: 40px;
        --bg-branding-width: 140px;
        --bg-hud-height: 40px;
        --bg-hud-line-height: 36px;
        --bg-attrib-bottom: 20px;
        --bg-attrib-width: 100px;
    }
}*/

/* detect lower resolution*/
@media only screen and (max-width: 1280px) and (max-device-height: 800px) {
	:root {
		--bg-font-size-smallest: calc(8px - 2px);
		--bg-font-size-smaller: calc(10px - 2px);
		--bg-font-size-small: calc(12px - 2px);
		--bg-font-size: calc(14px - 2px);
		--bg-font-size-large: calc(16px - 2px);
		--bg-font-size-larger: calc(18px - 2px);
		--bg-font-size-largest: calc(20px - 2px);
		--bg-navigator-height: calc(110px - 10px);
		--bg-imageryBlock-height: calc(110px - 20px);
		--bg-imageryBlock-width: calc(110px - 30px);
		--bg-imageButton-height: 40px;
		--bg-AOILibrary-height: calc(40px - 15px);
		--bg-AOILibrary-width: calc(400px - 100px);
		--bg-AOILibrary-select-arrow-offset: calc(18px - 8px);
		--bg-branding-height: calc(40px - 10px);
		--bg-branding-width: calc(140px - 35px);
		--bg-attrib-bottom: calc(20px + 5px);
		--bg-attrib-width: calc(100px - 5px);
	}
}

#lgv-root {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: black;
}

.map {
	position: absolute;
	top: 10px;
	left: 10px;
	bottom: 10px;
	right: 10px;
	height: 98%;
	width: 99%;
}

.loading {
	position: absolute;
	top: 25%;
	left: 0;
	bottom: 0;
	right: 0;
	height: auto;
	width: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 100;
}

.mapboxgl-map {
	overflow: visible;
}

.hidden {
	display: none !important;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.bold {
	font-weight: bold;
}

.left {
	float: left;
}

.right {
	float: right;
}

.center {
	float: unset;
}

.centre {
	text-align: center;
}

/* .centerRow {
	margin: auto 30%;
	text-align: center;
} */

.relative {
	position: relative;
	margin-left: 10px;
}

.yellow {
	color: yellow !important;
	background-color: yellow !important;
	border: 1px solid rgb(255, 204, 0) !important;
}

.paleYellow {
	color: rgb(246, 246, 154) !important;
}

.red {
	color: white !important;
	background-color: red !important;
	border: 1px solid red !important;
}

._red {
	color: red !important;
}

._red1 {
	color: red !important;
	font-size: x-small !important;
}

.blue {
	color: white !important;
	background-color: blue !important;
	border: 1px solid blue !important;
}

.green {
	color: white !important;
	background-color: green !important;
	border: 1px solid green !important;
}

.grey {
	color: white !important;
	background-color: grey !important;
	border: 1px solid grey !important;
}

.silver {
	color: black !important;
	background-color: silver !important;
	border: 1px solid silver !important;
}

.cyan {
	color: black !important;
	background-color: cyan !important;
	border: 1px solid cyan !important;
}

.magenta {
	color: black !important;
	background-color: magenta !important;
	border: 1px solid magenta !important;
}

._cyan {
	color: cyan !important;
}

._cyan1 {
	color: cyan !important;
	font-size: x-small !important;
}

.mapboxgl-popup {
	max-width: 300px !important;
	width: auto;
}

.mapboxgl-popup-content {
	background-color: rgba(0, 0, 0, var(--bg-opacity));
	color: white;
	width: auto;
}

.mapboxgl-popup-tip {
	color: rgba(255, 255, 255, var(--bg-opacity));
	filter: invert(1);
}

.tablet-lake-popup {
	left: calc(100% - 217px) !important;
	position: absolute !important;
	z-index: 999;
	top: 50px;
	transform: none !important;
}

.tablet-lake-popup .mapboxgl-popup-tip {
	display: none !important;
}

.react-confirm-alert-button-group button {
	height: 27px;
	font-size: 0.875rem;
	font-weight: bold;
}

.react-confirm-alert-overlay {
	background: unset !important;
}

.react-confirm-alert-body {
	color: white !important;
	background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

/* .mapboxgl-ctrl,
.suggestions,
.suggestions-wrapper,
.active a {
    color: white !important; 
} */

.mapboxgl-ctrl:not(.mapboxgl-ctrl-geocoder):not(.mapboxgl-ctrl-scale) {
	background-color: transparent !important;
	color: white !important;
}

.mapboxgl-control-container > div {
	font-family: var(--bg-font-family) !important;
	font-size: var(--bg-font-size) !important;
}

.mapboxgl-ctrl {
	margin: unset !important;
}

.mapboxgl-ctrl-group {
	margin-top: 10px !important;
	border: none !important;
	box-shadow: unset !important;
}

.mapboxgl-ctrl-bgwt {
	margin-top: 10px !important;
	font-weight: bold;
	font-size: var(--bg-font-size-large);
	border-radius: 4px;
	padding: 4px 4px 4px 4px;
	color: white !important;
	background-color: rgb(0, 0, 0) !important;
	border-radius: 4px;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder--suggestion,
.mapboxgl-ctrl button:not(.mapbox-gl-draw_ctrl-draw-btn),
.suggestions,
.suggestions-wrapper,
.active a,
.mapboxgl-ctrl-scale,
.mapboxgl-ctrl-logo {
	/* background-color: rgba(0, 0, 0, var(--bg-opacity)) !important; */
	border-radius: 4px;
}

.mapboxgl-ctrl-scale {
	position: absolute;
	top: 40px;
	right: 44px;
	height: 17px !important;
	color: #fff;
	text-shadow: 0px 1px 3px #000;
	font-family: Inter !important;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 300;
	line-height: 138%;
	background-color: transparent !important;
	width: 60px !important;
}

.mapboxgl-ctrl-scale.trendsFlag {
	right: 10px;
}

.mapboxgl-ctrl-scale.tablet {
	top: 41px;
	width: 60px !important;
}
.scale-icon-tablet {
	z-index: 9;
	position: absolute;
	right: 53px;
	top: 63px;
	width: 64px;
	height: 6.5px;
}
.scale-icon-tablet.trendsFlag {
	right: 20px;
}

.mapboxgl-ctrl button:not(.mapbox-gl-draw_ctrl-draw-btn) {
	background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
	border-radius: 4px;
	border-top-color: black;
}

.mapboxgl-ctrl-geocoder--powered-by {
	padding: 6px 12px 9px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
	text-decoration: none;
	color: #909090;
	font-size: 11px;
}

/* top-left control container */

.mapboxgl-ctrl-top-left {
	margin: 10px 0px 0px 10px !important;

	/* for new ui only */
	/* top: 144px; 
    left: 20px; */
}

.mapboxgl-ctrl-geocoder--input {
	border: 0px !important;
	background-color: white !important;
	color: black !important;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon {
	margin-left: -2px;
	margin-top: -2px;
	fill: black !important;
	background-color: white !important;
}

.mapboxgl-ctrl-geocoder--collapsed .mapboxgl-ctrl-geocoder--icon {
	fill: white !important;
	background-color: black !important;
}

.mapboxgl-ctrl-geocoder--collapsed {
	height: 29px !important;
	min-width: 29px !important;
	width: 29px !important;
	background-color: black !important;
}

.mapboxgl-ctrl-geocoder--collapsed input[type='text'] {
	width: 24px !important;
	padding: 0px !important;
	fill: white !important;
	background-color: black !important;
}

::placeholder {
	color: transparent;
}

.mapboxgl-ctrl-geocoder--icon:hover::placeholder,
.mapboxgl-ctrl-geocoder input[type='text']:hover::placeholder {
	color: grey;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-compass {
	margin-top: 5px;
}

.mapboxgl-ctrl-zoom-in {
	margin-top: unset;
}

.mapboxgl-ctrl-icon {
	filter: invert(1);
	background-color: transparent;
	color: white;
}

.mapbox-gl-draw_ctrl-draw-btn {
	filter: invert(1);
	color: white !important;
	background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.mapboxgl-ctrl-pitchtoggle {
	width: 30px !important;
	text-align: center !important;
}

.pitchtoggle {
	font-size: var(--bg-font-size) !important;
}

.view360 {
	font-size: var(--bg-font-size-smaller);
	text-align: center !important;
}

.mapboxgl-ctrl-dropPinControl,
.mapboxgl-ctrl-logoutControl {
	color: white !important;
	background-color: black !important;
	width: 34px !important;
}

.mapboxgl-ctrl-dropPinControl {
	width: 30px !important;
	text-align: center !important;
}

/* .mapboxgl-marker {
	width: 27px;
	height: 32px;
	border-radius: 4px;
	border: 1px dotted gray;
	background-image: url(images/LogoPin.png) !important;
} */

.dropPin {
	height: 21px;
	width: 21px;
	background-image: url(images/LogoPin-White.png);
}

.logout {
	content: url(images/logout.png);
	height: 21px;
	width: 21px;
}

.mapboxgl-ctrl-legendControl {
	position: fixed;
	top: 60px;
	left: 60px;
	font-size: var(--bg-font-size-smaller);
}

#bgiLegend {
	line-height: 25px;
	background-color: transparent;
	z-index: 1;
}

#bloomLegend {
	/*position: relative;
    top: 0px;*/
	line-height: 25px;
	background-color: transparent;
	z-index: 1;
}

.legend table {
	position: relative;
	opacity: var(--bg-opacity);
	display: contents;
}

.legend table tr td {
	width: 135px;
	min-width: 135px;
}

.legendHeader {
	color: white;
	font-size: var(--bg-font-size-large);
	font-weight: bold;
	position: relative;
	text-align: center;
	background-color: transparent;
	overflow-y: visible;
}

.legendRow {
	line-height: 20px;
	height: 20px;
}

.legendCell {
	color: white;
	background: transparent;
	font-weight: bold;
	opacity: var(--bg-opacity);
	z-index: 1;
	text-align: center;
	padding: 4px;
}

.legendCell.dark {
	color: #000;
}

/* top-right control container */

.mapboxgl-ctrl-top-right {
	margin: 10px 10px 0px 0px !important;
}

.mapboxgl-ctrl-brandingControl {
	position: fixed;
	top: 0px !important;
	margin-top: 10px !important;
	right: 0px !important;
	margin-right: 20px !important;
	width: var(--bg-branding-width);
	height: var(--bg-branding-height);
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.8);
	padding-top: 5px;
}

.branding {
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-image: url(images/logo.png);
	background-repeat: no-repeat;
	background-size: contain;
}

.mapboxgl-ctrl-hudControl {
	position: fixed;
	top: 93px !important;
	margin-top: 10px !important;
	height: 100% !important;
	max-height: var(--bg-hud-height);
	right: calc(var(--bg-branding-width) + 35px);
	width: auto;
	pointer-events: auto;
	z-index: 9999;
}

.hud {
	height: var(--bg-hud-height);
	line-height: var(--bg-hud-line-height);
	border: 0px !important;
	border-radius: 4px;
}

.hud span {
	color: white;
	font-size: var(--bg-font-size-large);
	font-weight: bold;
	padding: 2px 5px 2px 5px !important;
	background-color: rgba(0, 0, 0, var(--bg-opacity));
	border-radius: 4px;
}

.mapboxgl-ctrl-AOILibraryControl {
	position: fixed;
	top: 10px !important;
	margin-top: 10px !important;
	height: auto !important;
	max-height: calc(var(--bg-AOILibrary-height));
	right: calc(var(--bg-branding-width) + 35px);
	width: auto;
	pointer-events: auto;
	z-index: 9999;
	border-radius: 4px;
}

.AOILibraryControl {
	color: white !important;
	background-color: transparent;
	border: 0px !important;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.AOILibraryControl select {
	display: none;
	height: 0px;
}

.select-selected {
	background-color: transparent;
	color: white;
	border-radius: 4px;
	height: calc(var(--bg-AOILibrary-height));
	line-height: var(--bg-AOILibrary-height);
	box-sizing: content-box !important;
	padding-left: 10px;
	cursor: pointer;
	font-size: var(--bg-font-size-large);
	font-weight: bold;
}

.select-selected:after {
	position: absolute;
	content: '';
	top: var(--bg-AOILibrary-select-arrow-offset);
	right: 5px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: white transparent transparent transparent;
	color: white;
}

.select-selected.select-arrow-active:after {
	border-color: transparent transparent #fff transparent;
	top: calc(var(--bg-AOILibrary-select-arrow-offset) - 5px);
}

.select-items {
	top: 100%;
	left: 0;
	right: 0;
	z-index: 9999;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	height: var(--bg-AOILibrary-list-height);
	max-height: var(--bg-AOILibrary-list-height);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: grey black;
	color: white !important;
	background-color: transparent !important;
}

.select-items div {
	font-size: var(--bg-font-size);
	font-weight: bold;
	background-color: transparent;
	color: white;
	padding: 8px 10px;
	cursor: pointer;
	z-index: 9999;
}

.select-items div:first-child {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

.select-items div:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	color: teal;
}

/* bottom-right control container */

.mapboxgl-ctrl-bottom-right {
	margin: 0px 10px 10px 0px !important;
}

.mapboxgl-ctrl-attrib {
	position: fixed;
	bottom: var(-bg-attrib-bottom) !important;
	right: var(--bg-attrib-width) !important;
	width: auto !important;
	height: var(--bg-font-size-smaller) !important;
	margin-right: 5px !important;
}

.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib-inner a {
	color: white !important;
	background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
	border-radius: 4px;
	font-size: var(--bg-font-size-smaller) !important;
	font-weight: bold;
}

.mapboxgl-ctrl-attrib-inner {
	padding: 2px 5px 2px 5px !important;
}

.mapboxgl-ctrl-attrib {
	position: absolute !important;
	display: contents !important;
	color: #fff;

	text-shadow: 0px 1px 3px #000;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 138%; /* 16.56px */
}

.mapboxgl-ctrl-attrib .mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib .mapboxgl-ctrl-attrib-inner a {
	background-color: transparent !important;
	color: #fff !important;

	text-shadow: 0px 1px 3px #000;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 138%; /* 16.56px */
}
.mapboxgl-ctrl-attrib.tablet .mapboxgl-ctrl-attrib-inner {
	max-width: 490px;
}

.mapbox-improve-map {
	display: none;
}

/* bottom-left control container */

.mapboxgl-ctrl-bottom-left {
	margin: 0px 0px 10px 10px !important;
	display: grid !important;
	grid-template-columns: auto auto !important;
	grid-template-rows: 20px !important;
	grid-column-gap: -2px !important;
}

.mapboxgl-ctrl-scale {
	/* background-color: rgba(0, 0, 0, var(--bg-opacity)) !important; */
	border: 0px !important;
	font-size: var(--bg-font-size-small);
	height: 24px !important;
	padding: 2px 5px 2px 5px !important;
	/* width: auto !important; */
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-start-end-radius: 0px !important;
	border-end-end-radius: 4px !important;
	border-image-width: 0 !important;
	margin-left: -0px !important;
	font-weight: bold;
	color: white !important;
}

.mapboxgl-ctrl-coordinates {
	width: auto !important;
	white-space: nowrap !important;
	border: 0px !important;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-start-start-radius: 4px !important;
}

.coordinates {
	color: white;
	font-size: var(--bg-font-size-small);
	font-weight: bold;
	padding: 2px 5px 2px 5px !important;
	background-color: rgb(0, 0, 0, var(--bg-opacity)) !important;
}

.coordinates.new-ui {
	position: absolute;
	top: 40px;
	width: 349px;
	right: 418px;
	height: 17px;
	color: #fff;
	text-shadow: 0px 1px 3px #000;
	font-family: Inter !important;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 300;
	line-height: 138%; /* 16.56px */
	background-color: transparent !important;
}

.coordinates.new-ui.trendsFlag {
	right: 367px;
}

/* locationEditor components */

.locationEditor {
	position: absolute;
	margin: 20% 20%;
	background-color: rgb(0, 0, 0, var(--bg-opacity));
	border-radius: 4px;
	color: white;
	padding: 10px;
	display: inline;
	margin-right: 10px;
}

.locationEditor .heading {
	width: 100%;
	text-align: center;
}

.locationEditor table,
.locationEditor table tbody,
.locationEditor table tbody tr {
	border-collapse: separate;
	border-spacing: 5px;
	max-width: var(--bg-locationEditor-width);
}

.locationEditor textarea,
.locationEditorContent input {
	font-family: var(--bg-font-family) !important;
	font-size: var(--bg-font-size) !important;
	border-width: 1px;
	border-style: solid;
	border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
	border-image: initial;
}

.locationEditor .name,
.locationEditor .description {
	width: 320px !important;
	max-width: 320px !important;
	min-width: 320px !important;
}

.locationEditor .react-datepicker__tab-loop {
	position: relative !important;
	top: 10px !important;
}

.react-confirm-alert-button-group button,
.popupButton,
.btnSave,
.btnSaveLocation,
.btnCloseLocation {
	text-decoration: none !important;
	color: #3f51b5 !important;
	background-color: #3f51b5 !important;
	border: 1px solid #2a3991 !important;
	border-radius: 4px !important;
	color: #fff !important;
	padding: 3px 5px 4px 5px !important;
	cursor: pointer;
}

.btnCloseLocation {
	float: right;
	color: #fff !important;
}

.btnModify,
.btnDelete,
.btnSave,
.btnSaveLocation {
	float: left;
	margin-right: 10px !important;
}

.btnLogin {
	min-height: 40px;
	height: 40px;
	cursor: grab;
}

.react-confirm-alert-button-group button:last-child,
.btnDelete:not(:disabled) {
	color: #fff !important;
	background-color: #f50057 !important;
	padding-bottom: 1px !important;
}

.btnDownload {
	float: right;
	margin-right: 20px !important;
	background-color: orange !important;
	padding-bottom: 1px !important;
}

.btnCancelLocation {
	color: white !important;
	background-color: #f50057 !important;
	padding-bottom: 1px !important;
	float: right;
}

/* imagery components */

.navigator {
	position: fixed;
	bottom: 0px;
	left: 15px;
	height: var(--bg-navigator-height);
	max-height: var(--bg-navigator-height);
	min-height: var(--bg-navigator-height);
	width: var(--bg-navigator-width);
	max-width: var(--bg-navigator-width);
	min-width: var(--bg-navigator-width);
	border-radius: 10px;
	overflow-x: clip;
	overflow-y: hidden;
	margin: 0px 5px 50px 5px;
	display: flex;
	background-color: rgb(0, 0, 0, var(--bg-opacity));
	color: white;
}

.imagery {
	width: var(--bg-imagery-width);
}

.imageryLeft,
.imageryRight {
	position: relative;
	top: 30px;
	margin-top: 10px;
	min-width: 50px;
	min-height: 90px;
	text-align: center;
	font-size: 64px;
	cursor: grab;
}

.imageryLeft {
	float: left;
}

.imageryRight {
	float: right;
}

.swiper {
	position: absolute !important;
	top: 30px;
	left: 40px;
	height: calc(var(--bg-navigator-height) - 30px);
	max-height: calc(var(--bg-navigator-height) - 30px);
	width: calc(var(--bg-imagery-width) - 90px) !important;
	z-index: 0 !important;
	float: left;
}

.swiper .swiper-wrapper {
	width: calc(var(--bg-imagery-width) - 120px) !important;
}

.swiper-button-next,
.swiper-button-prev {
	color: white !important;
	font-weight: bold !important;
	height: 100% !important;
	top: 15% !important;
	display: none !important;
}

.swiper-slide {
	width: var(--bg-imageryBlock-width) !important;
}

.imagerySelector {
	position: absolute;
	width: var(--bg-imagerySelector-width);
	height: var(--bg-imagerySelector-height);
	line-height: var(--bg-imagerySelector-height);
	font-weight: bold;
	z-index: 1;
	border-image-width: 0;
	padding-top: 5px;
	vertical-align: middle;
}

.imagerySelectorButton {
	background-color: silver;
	width: var(--bg-imagerySelector-button-width);
	text-align: center;
	font-weight: bold;
	border-radius: 4px;
	height: var(--bg-imagerySelector-button-height);
	line-height: var(--bg-imagerySelector-height);
	margin-left: 5px;
	display: inline;
	cursor: pointer;
}

.imagerySelectorActionButton {
	background-color: silver;
	width: var(--bg-imagerySelector-action-button-width);
	text-align: center;
	font-weight: bold;
	border-radius: 4px;
	height: var(--bg-imagerySelector-button-height);
	line-height: var(--bg-imagerySelector-height);
	margin-left: 5px;
	display: inline;
	cursor: pointer;
}

.imagerySelectorButton:hover {
	background-color: #3f51b5;
	color: white;
}

.imagerySelectorButton:last-child {
	margin-right: 5px;
}

.imageryTypeSelected {
	background-color: cyan;
}

.datePickerWrapper {
	display: inline-flex;
	color: #fff;
	background-color: rgb(0, 0, 0, var(--bg-opacity));
	font-weight: bold;
	text-align: center;
	color: black;
	line-height: var(--bg-imagerySelector-height);
	border-radius: 4px;
	border: none !important;
}

.datePickerWrapper label {
	color: white;
	white-space: nowrap;
	height: var(--bg-imagerySelector-button-height);
	line-height: var(--bg-imagerySelector-height);
}

.datePickerWrapper input {
	border: none !important;
	border-radius: 4px !important;
	border-image-width: 0 !important;
	border-color: transparent !important;
	height: var(--bg-imagerySelector-height);
	line-height: var(--bg-imagerySelector-height);
	width: 130px;
	text-align: center;
	cursor: pointer;
}

.dateRanges {
	width: 80px;
	margin-right: 205px;
}

.dateRanges input {
	width: 80px;
}

.react-datepicker,
.react-datepicker__header button,
.react-datepicker__header select,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__tab-loop,
.timeSeriesStart,
.dateRangeStart {
	border: unset !important;
	border-radius: 4px !important;
	background-color: rgb(0, 0, 0, var(--bg-opacity)) !important;
	color: #fff !important;
}

.react-datepicker__tab-loop,
.timeSeriesStart,
.dateRangeStart {
	position: fixed !important;
	bottom: 235px !important;
}

.startMonth .react-datepicker__tab-loop {
	float: left !important;
	left: 400px !important;
}

.dateRanges .react-datepicker__tab-loop {
	float: right !important;
	right: 550px !important;
}

.react-datepicker__header {
	background-color: transparent !important;
	color: #fff !important;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown-container,
.react-datepicker__month-read-view,
.react-datepicker__year-dropdown-container {
	background-color: rgba(0, 0, 0, 1) !important;
	color: #fff !important;
	margin-right: 10px !important;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
	margin-top: -20px !important;
	border: unset !important;
	border-radius: 4px !important;
}

.react-datepicker__header-custom:not(div) {
	margin: 10;
	display: flex;
	justify-content: center;
}

.react-datepicker-popper {
	position: unset !important;
	transform: unset !important;
	inset: unset !important;
	background-color: transparent !important;
	border-radius: 4px !important;
}

.react-datepicker-popper[data-placement^='bottom'] {
	padding-top: unset !important;
}

.react-datepicker__month-container {
	background-color: transparent !important;
	opacity: var(--bg-opacity) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
	background-color: #216ba5 !important;
}

.timeSeries {
	background-color: orange;
	width: 100px;
	padding: 5px;
	text-align: center;
}

.imageryBlock {
	position: relative;
	left: 0px;
	height: var(--bg-imageryBlock-height);
	width: var(--bg-imageryBlock-width);
	display: inline-table;
	margin-top: -5px;
	padding: 10px;
}

.imageryBlock .imageButton {
	height: var(--bg-imageButton-height) !important;
	width: var(--bg-imageButton-width) !important;
	object-fit: contain;
}

.imageryDate {
	font-size: var(--bg-font-size);
	font-weight: bold;
	margin-top: -50px;
}

.selected {
	border: 1px solid yellow !important;
}

.spanImagery {
	height: var(--bg-navigator-height);
	max-height: var(--bg-navigator-height);
	min-height: var(--bg-navigator-height);
	display: inherit;
	padding: 10px;
	width: calc(100% - 150px);
	max-width: calc(100% - 160px);
	min-width: calc(100% - 160px);
	overflow-y: hidden;
	overflow-x: scroll;
}

.imageButton {
	z-index: 1;
	height: var(--bg-button-height);
	background-color: rgb(32, 32, 32);
}

.imageButtonTrueColour {
	background-color: rgb(82, 81, 81);
}

/* imageryDetails components */

.imageryDetails {
	position: fixed;
	top: 60px;
	right: 35px;
	width: var(--bg-imageryDetails-width);
	min-width: var(--bg-imageryDetails-width);
	height: var(--bg-imageryDetails-height);
	max-height: var(--bg-imageryDetails-height);
	border-radius: 4px;
	padding: 10px;
	z-index: 5;
	background-color: rgb(0, 0, 0, var(--bg-opacity));
	color: white;
}

/* .imageryDetailsLayerMode .controls {
	font-size: var(--bg-font-size-xl);
	margin-top: -10px;
}

.imageryDetailsLayerMode .controls .close {
	color: white;
	font-weight: bold;
	cursor: pointer;
}

.imageryDetailsLayerMode .controls .close:hover {
	color: red;
} */

/* .imageryDetails .trueColourHeading .trueColourSelected {
	font-weight: bold;
	background-color: '#030303';
} */

/* .imageryDetailsLayerModeContainer {
	width: var(--bg-imageryDetails-width) !important;
} */

/* .imageryDetailsLayerMode {
	color: #0ff;
}

.imageryDetailsTrueColourHeading {
	width: 50%;
}

.imageryDetailsLayerMode .left {
	margin-left: 20px !important;
}

.imageryDetailsLayerMode .right:not(.controls) {
	margin-right: 20px !important;
}

.imageryDetailsLayerMode .trueColourSelected {
	color: cyan;
} */

/* .imageryDetailsDate, */
/* .imageryDetailsWeather {
	font-weight: bold;
	text-align: center;
} */

.buttonImageryDetails_bloom,
/* .buttonImageryDetails_bgi, */
/* .buttonImageryDetails_trueColour, */
.buttonImageryDetails_planet {
	height: calc(var(--bg-imageButton-height)) !important;
	width: calc(var(--bg-imageButton-width)) !important;
	object-fit: contain;
	margin-bottom: 20px;
	background-color: rgb(32, 32, 32);
}

/* .buttonImageryDetails_trueColour {
	margin-top: 10px;
	background-color: rgb(82, 81, 81);
} */

.buttonRotate {
	background-color: green !important;
}

.buttonRotate.active {
	background-color: red !important;
}

/* .tblImageryDetails,
.tblImageryDetails tbody,
.tblImageryDetails tr {
	min-width: 100%;
	width: 100%;
} */

/* .tblImageryDetails td {
	padding: 0px 40px 10px 0px;
	font-size: var(--bg-font-size);
	min-width: 100%;
	width: 100%;
	cursor: pointer;
} */

/* .imageryDetailsImage {
	height: calc(var(--bg-imageButton-height)) !important;
	width: 100%calc (var(--bg-imageButton-width)) !important;
	object-fit: contain;
	margin-bottom: 0px;
	text-align: center;
}

.imageryDetailsImage .noImage {
	width: 100% !important;
} */

/* .imageryDetailsUnavailable {
	color: rgb(246, 246, 154);
} */

.tblImageryDetails tbody tr td:last-child {
	padding: 0px 0px 00px 0px;
}

.mapPointDetails {
	width: var(--bg-mapPointDetails-width);
	color: white !important;
	background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
	border-radius: 4px !important;
	max-height: 50%;
}

.mapboxgl-popup-tip {
	background-color: transparent !important;
}

.art {
	position: absolute;
	bottom: calc(var(--bg-navigator-height) + 60px);
	top: 60px;
	left: 60px;
	right: 20px;
	border: none solid rgb(0, 0, 0, var(--bg-opacity));
	border-radius: 5px;
}

.artContent {
	height: 100%;
	width: 100%;
	border: none solid rgb(0, 0, 0, var(--bg-opacity));
	border-radius: 5px;
	z-index: 10;
}

.btnShowInsitu {
	float: left;
	margin-right: 10px !important;
}

.react-confirm-alert-body {
	height: auto;
	max-height: 700px;
	display: table;
}

.releaseNotes {
	height: auto;
	max-height: 600px;
	width: 500px;
	overflow-y: auto;
}

.controls-new-ui .mapboxgl-ctrl.mapboxgl-ctrl-group {
	top: 74px;
	position: absolute;
	border-radius: 24px;
}

.controls-new-ui.basic-user .mapboxgl-ctrl.mapboxgl-ctrl-group {
	top: 40px;
	position: absolute;
	border-radius: 24px;
}

.controls-new-ui.tablet .mapboxgl-ctrl.mapboxgl-ctrl-group {
	top: 137px;
	position: absolute;
}

.mapboxgl-ctrl-top-left.controls-new-ui {
	top: 0px;
}

.planet {
	color: black;
	background-color: yellow;
}

/* NEW DESIGN RELATED CLASSES HERE */

.mapboxgl-ctrl-fullscreen,
.mapboxgl-ctrl-shrink {
	display: flex;
	width: 28px;
	height: 28px;
	padding: 4px;
	justify-content: center;
	align-items: center;
	border-radius: 20px !important;
}

.mapboxgl-ctrl-zoom-in {
	display: flex;
	width: 28px;
	height: 24px;
	justify-content: center;
	align-items: center;
	border-radius: 20px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-top: 111px;
}

.mapboxgl-ctrl-zoom-in.tablet {
	width: 37px;
	height: 37px;
	margin-top: 6px;
}

.mapboxgl-ctrl-zoom-out {
	margin-top: 0px !important;
	display: flex;
	width: 28px;
	height: 24px;
	justify-content: center;
	align-items: center;
	border-top: 0px !important;
	border-radius: 20px !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.mapboxgl-ctrl-zoom-out.tablet {
	width: 37px;
	height: 37px;
}

.mapboxgl-ctrl-compass {
	/* display: none !important; */
	top: 61px !important;
	position: absolute;
	border-radius: 20px !important;
}

.mapboxgl-ctrl-compass.tablet {
	display: none !important;
}
.mapbox-gl-draw_polygon {
	/* display: none !important; */
	top: 28px !important;
	left: 0px;
	position: absolute;
}
.mapbox-gl-draw_polygon.tablet {
	display: none !important;
}

.mapboxgl-ctrl-fullscreen {
	top: 0px;
	position: absolute;
}

.mapboxgl-ctrl-geocoder--input {
	border-radius: 16px !important;
	color: rgba(0, 0, 0, 0.5) !important;
	border: 1px solid rgba(0, 0, 0, 0.16) !important;
	height: 28px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
	background-color: transparent !important;
	height: 28px !important;
	width: 28px !important;
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.71) !important;
	display: flex;
	text-align: center;
	align-items: center;
	top: 47px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl svg {
	top: 6px;
	left: 6px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl.tablet {
	height: 37px !important;
	width: 37px !important;
}
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl.tablet svg {
	top: 10px;
	left: 10px;
	height: 22px;
	width: 22px;
}

.cursor-pointer:hover {
	cursor: pointer;
	z-index: 7;
}

.map canvas {
	width: 100% !important;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

/* NEW DESIGN RELATED CLASSES END */

.read-more {
	position: absolute;
	top: 100px;
	right: 477px;
	background: white;
	width: 55px;
	height: 45px;
	border-radius: 23px 0px 0px 23px;
	padding-left: 5px;
	z-index: 10;
	display: none;
}

.read-more.collapsed {
	right: 0;
}

.first-main-divider {
	width: 28px;
	position: absolute;
	z-index: 6;
	top: 206px;
	left: 20px;
	height: 1px;
	opacity: 0.5;
	background: #d9d9d9;
}

.first-main-divider.basic-user {
	top: 173px !important;
}

.first-main-divider.tablet {
	width: 37px;
	top: 161px;
}

.second-main-divider {
	width: 28px;
	position: absolute;
	z-index: 6;
	top: 275px;
	left: 20px;
	height: 1px;
	opacity: 0.5;
	background: #d9d9d9;
}

.second-main-divider.basic-user {
	top: 243px !important;
}

.second-main-divider.tablet {
	width: 37px;
	top: 256px;
}

.center-icon {
	height: 28px !important;
	width: 28px !important;
	position: absolute;
	padding: 6px;
	top: 285px;
	left: 20px;
	background: rgba(0, 0, 0, 0.71);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}

.center-icon.basic-user {
	top: 248px !important;
}

.center-icon.tablet {
	height: 37px !important;
	width: 37px !important;
	top: 263px;
}

@media (orientation: portrait) {
	.scale-icon-tablet {
		/* top: 118px; */
		top: 122px;
		right: 57px;
	}

	.coordinates.new-ui {
		top: 100px;
		right: 16px;
	}

	.mapboxgl-ctrl-scale {
		top: 100px !important;
		right: 50px;
	}
	.mapboxgl-ctrl-scale.tablet {
		top: 100px !important;
	}
	.read-more {
		display: block;
		top: 150px !important;
	}

	.read-more.tablet {
		right: 399px;
	}
	.read-more.collapsed {
		display: block;
		right: 0;
	}

	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
		top: 120px !important;
	}

	.controls-new-ui.tablet .mapboxgl-ctrl.mapboxgl-ctrl-group {
		top: 201px !important;
	}
	.controls-new-ui .mapboxgl-ctrl.mapboxgl-ctrl-group {
		top: 156px !important;
	}

	.controls-new-ui.basic-user .mapboxgl-ctrl.mapboxgl-ctrl-group {
		top: 113px !important;
	}
	.controls-new-ui.tablet.basic-user .mapboxgl-ctrl.mapboxgl-ctrl-group {
		top: 201px !important;
	}

	.first-main-divider {
		top: 291px !important;
	}

	.first-main-divider.basic-user {
		top: 244px !important;
	}

	.first-main-divider.tablet {
		top: 227px !important;
	}

	.second-main-divider {
		top: 356px !important;
	}

	.second-main-divider.basic-user {
		top: 318px !important;
	}

	.second-main-divider.tablet {
		top: 320px !important;
	}

	.center-icon {
		top: 370px !important;
	}

	.center-icon.tablet {
		top: 334px !important;
	}

	.center-icon.basic-user {
		top: 323px !important;
	}
}

.copyright-tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.copyright-tooltip .tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
}

.copyright-tooltip.visible .tooltiptext {
	visibility: visible;
}

.copyright-tooltip .tooltiptext {
	width: 100px;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
	margin-bottom: 10px;
}

.marker {
	background-image: url(images/SensorMap.png) !important;
	background-size: cover;
	min-width: 16px;
	max-width: 16px;
	height: 16px;
	border-radius: 50%;
	cursor: pointer;
}

.marker.disabled {
	background-image: url(images/SensorsMapDisabled.png) !important;
}

.sensor-layer-title {
	color: rgba(255, 255, 255, 0.9);
	font-family: Inter !important;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: normal;
	height: 15px;
	margin: 0;
}

.sensor-layer-date {
	color: rgba(255, 255, 255, 0.9);
	font-family: Inter !important;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 300 !important;
	line-height: normal;
	height: 15px;
	opacity: 0.5;
	margin-bottom: 7px;
}

.sensor-layer-content {
	color: rgba(255, 255, 255, 0.9);
	font-family: Inter !important;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 400 !important;
	margin: 0;
	height: 15px;
}

.sensors-map-container {
	width: 60px;
	height: 16px;
	border-radius: 12px;
	border: 1px solid #00c2ff;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	align-items: center;
}
.sensors-map-container.disabled {
	border: 0px;
}

.sensors-map-text {
	color: rgba(255, 255, 255, 0.9);
	font-family: Inter !important;
	font-size: 11px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-left: 4px;
	height: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.sensors-map-text .disabled {
	color: rgba(255, 255, 255, 0.65) !important;
}

.sensors-map-popup {
	display: flex;
	width: 182px;
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 0px;
	grid-gap: 0px;
	height: 124px;
}
